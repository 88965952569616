<template>
    <form-modal
        ref="formModal"
        :title="i18nTitleModal"
        :in-editing="isEdit"
        :size="tabIndex === 0 ? 'lg' : 'xl'"
        :handle-close="handleCancel"
    >
        <template v-slot:form>
            <form @submit.prevent="handleOk">
                <confirmation-form-modal
                    ref="confirmationFormModal"
                    :title="i18nRole"
                    :operation="isEdit ? modalOperation : 'deleted'"
                />
                <feedback-modal
                    ref="feedbackModal"
                    :title="i18nRole"
                />
                <b-row v-if="!isEdit" class="mb-2">
                    <div class="col-sm-6 form-inline">
                    </div>
                    <div class="col-sm-6">
                        <div class="text-sm-right">
                            <edit-label-button
                                :title="getI18n(i18nKey, 'TITLES.role')"
                                :disabled="deleteLoading"
                                @onClick="isEdit = true"
                            />
                            <delete-label-button
                                :title="getI18n(i18nKey, 'TITLES.role')"
                                :busy="deleteLoading"
                                @onClick="handleDelete"
                            />
                        </div>
                    </div>
                </b-row>
                <b-tabs
                    id="role-tabs"
                    v-model="tabIndex"
                    class="tabs-pdv"
                    @activate-tab="tabActivated">
                    <b-tab :title="i18nInformation">
                        <b-form-row>
                            <b-col md="6">
                                <b-form-group
                                    :label="i18nName"
                                    class="required label-pdv"
                                    label-for="name-input"
                                    :invalid-feedback="i18nInvalidName"
                                >
                                    <b-form-input
                                        id="alias-input"
                                        v-model.trim="$v.roleModal.alias.$model"
                                        class="input-pdv-blue"
                                        name="name"
                                        type="text"
                                        :state="validateField('alias', 'roleModal')"
                                        :disabled="isRoleDefault || !isEdit || submitLoading"
                                        autocomplete="off"
                                        @input="clearResponseError('alias', 'roleModal')"
                                        @blur="$v.roleModal.alias.$touch"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                            <b-col md="6">
                                <b-form-group
                                    :label="i18nParentRole"
                                    class="label-pdv"
                                    label-for="role-modal"
                                    :invalid-feedback="i18nInvalidRole"
                                >
                                    <multiSelectWithService
                                        :id="'role-modal'"
                                        ref="parentRoleSelect"
                                        v-model="roleModal.parent"
                                        :input-function="$v.roleModal.parent_id.$touch"
                                        :select-class="validationClass($v.roleModal.parent_id)"
                                        label="alias"
                                        :service="'roles'"
                                        :searchable="true"
                                        :multiple="false"
                                        :disabled="isRoleDefault || !isEdit || submitLoading"
                                        @input="changeParentRoleId"
                                    />
                                </b-form-group>
                            </b-col>
                        </b-form-row>
                    </b-tab>
                    <b-tab :title="i18nPermissionsTab(2)">
                        <permissions-tab
                            ref="permissionsTab"
                            v-model="roleModal.permissions"
                            :role-id="roleModal.id"
                            :parent-id="roleModal.parent_id"
                            :busy="submitLoading || deleteLoading"
                            :disabled="!isEdit"
                        />
                    </b-tab>
                </b-tabs>
            </form>
        </template>
        <template v-slot:footer>
            <div v-if="isEdit" class="d-flex justify-content-center">
                <cancel-button
                    ref="cancelButton"
                    child-class="col-sm-4 float-left"
                    :disabled="submitLoading"
                    @onClick="handleCancel"
                />
                <save-button
                    v-if="tabIndex === 0"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :title="getI18n('COMMON.next')"
                    :busy="submitLoading"
                    @onClick="tabIndex = 1"
                />
                <save-button
                    v-else-if="isRoleCreated"
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
                <add-button
                    v-else
                    ref="okButton"
                    child-class="col-sm-4 float-right ml-3"
                    :busy="submitLoading"
                    @onClick="handleOk"
                />
            </div>
        </template>
    </form-modal>
</template>

<script>
import swalFeedback from '@src/mixins/swalFeedback.vue'
import validationMixin from '@src/mixins/validation.vue'
import FormModal from '@/src/components/FormModal.vue'
import ConfirmationFormModal from '@src/components/ConfirmationFormModal'
import FeedbackModal from '@src/components/FeedbackModal'
import fieldsValidation from '@src/mixins/fieldsValidation.vue'
import RolesService from '@src/services/RolesService.js'
import multiSelectWithService from '@src/components/multiSelectWithService'
import {
    numeric,
    minLength,
    required,
} from 'vuelidate/lib/validators'
import PermissionsTab from '@views/roles/permissionsTab'
import CancelButton from '@/src/components/CancelButton'
import AddButton from '@/src/components/AddButton'
import SaveButton from '@/src/components/SaveButton'
import EditLabelButton from '@/src/components/EditLabelButton.vue'
import DeleteLabelButton from '@/src/components/DeleteLabelButton.vue'

const i18nKey = 'SECURITY'
const i18nCommon = 'COMMON'

export default {
    components: {
        PermissionsTab,
        FormModal,
        ConfirmationFormModal,
        FeedbackModal,
        multiSelectWithService,
        CancelButton,
        AddButton,
        SaveButton,
        EditLabelButton,
        DeleteLabelButton
    },
    mixins: [validationMixin, swalFeedback, fieldsValidation],
    props: {
        modal: {
            type: Object,
            default: () => {
                return {
                    id: 0,
                    alias: null,
                    parent: {
                        id: 0,
                        name: '',
                        alias: '',
                    },
                    permissions: [],
                    parent_id: 0,
                }
            },
        },
        modalIsEdit: {
            required: true,
            type: Boolean,
        },
    },
    data() {
        return {
            i18nKey,
            i18nCommon,
            roleModal: this.modal,
            tabIndex: 0,
            isEdit: false,
            submitLoading: false,
            deleteLoading: false,
            isRoleDefault: false,
        }
    },
    validations: {
        roleModal: {
            alias: {
                required,
                minLength: minLength(2),
            },
            parent_id: {
                numeric,
            },
        },
    },
    computed: {
        isRoleCreated() {
            return this.roleModal.id > 0
        },
        i18nTitleModal() {
            if (this.isRoleCreated) {
                if (this.isEdit) {
                    return `${this.getI18n(i18nCommon, 'edit')} ${this.i18nRole}`
                }
                return this.roleModal.alias
            }
            else {
                return this.getI18n(i18nKey, 'BUTTONS.new_role')
            }
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nParentRole() {
            return this.getI18n(i18nKey, 'TITLES.parent_role')
        },
        i18nInvalidRole() {
            return this.getI18n('ERROR_CODES.invalid_role')
        },
        i18nInvalidName() {
            return this.getI18n('ERROR_CODES.invalid_name')
        },
        i18nPermissions() {
            return this.getI18n(i18nKey, 'TITLES.permission')
        },
        i18nInformation() {
            return this.getI18nModified({
                prefix: 'COMMON.info',
                modifier: 2,
            })
        },
        i18nRole() {
            return this.getI18n(i18nKey, 'TITLES.role')
        },
        modalOperation() {
            return this.isRoleCreated ? 'edited' : 'created'
        },
    },
    methods: {
        tabActivated(newTabIndex, oldTabIndex, event) {
            if (this.isEdit) {
                if (oldTabIndex === 0) {
                    this.$v.roleModal.$touch()
                    if (this.$v.roleModal.$invalid) {
                        event.preventDefault();
                    }
                }
            }

            if (newTabIndex === 1) {
                this.$nextTick(() =>
                    this.$refs.permissionsTab.loadAllPermissions(this.roleModal.permissions)
                )
            }
        },
        i18nPermissionsTab(modifier) {
            return this.getI18nModified({
                prefix: 'SECURITY.TITLES',
                suffix: 'permission',
                modifier: modifier,
            })
        },
        changeParentRoleId(e) {
            const oldParentId = this.roleModal.parent_id
            this.roleModal.parent_id = e ? e.id : null
            this.clearResponseError('parent_id', 'roleModal')

            if (oldParentId !== this.roleModal.parent_id) {
                this.roleModal.permissions = []
            }
        },
        showModal() {
            this.isEdit = this.modalIsEdit
            this.submitLoading = false
            this.deleteLoading = false
            this.isRoleDefault = this.checkDefaultRole()
            this.tabIndex = 0
            this.$refs.formModal.show()
        },
        clearAllResponseError() {
            this.modalResponseErrors = {}
        },
        cleanModal() {
            this.roleModal = Object.assign(this.roleModal, {
                id: 0,
                alias: null,
                parent: {
                    id: 0,
                    name: '',
                    alias: '',
                },
                permissions: [],
                parent_id: 0,
            })
            this.tabIndex = 0
            this.resetValidation()
        },
        handleCancel() {
            if (this.isEdit) {
                this.$refs.confirmationFormModal.showModal(true, this.handleClose)
            }
            else {
                this.handleClose(true)
            }
        },
        handleClose(close) {
            if (!close) {
                return;
            }
            this.resetValidation()
            this.$refs.formModal.hide()
        },
        resetValidation() {
            this.$nextTick(() => this.$v.$reset())
            this.clearAllResponseError()
        },
        checkFormValidity() {
            let isValidForm = true

            this.$v.roleModal.$touch()

            if (this.$v.$anyError) {
                isValidForm = false
            }

            return isValidForm
        },

        handleOk() {
            this.$v.roleModal.$touch()
            if (!this.$v.roleModal.$invalid && !this.submitLoading) {
                this.$refs.confirmationFormModal.showModal(false, this.handleSubmit)
            }
        },

        async handleSubmit(submit) {
            if (!submit) {
                return;
            }

            this.submitLoading = true

            const permissionIds = this.roleModal.permissions.map((permission) => permission.id)

            if (this.roleModal.parent_id === 0) {
                this.roleModal.parent_id = null
            }

            const operation = this.isRoleCreated
                ? RolesService.update(this.roleModal.id, this.roleModal)
                : RolesService.create(this.roleModal)

            const response = await operation
                .then((response) => response)
                .catch((errors) => errors)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                if (!this.isRoleCreated) {
                    this.roleModal.id = response.data.data.id
                }

                await RolesService.batchAssignPermissionsToRole(this.roleModal.id, {
                    permissions: permissionIds,
                })

                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nRole, this.modalOperation)
            }

            if (statusCode === '417') {
                this.warningFeedback(this.i18nRole, response.data.message)
            }

            if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
                this.warningFeedbackApi(this.i18nRole, response.data.errors)
            }

            this.submitLoading = false
        },

        handleDelete() {
            if (this.deleteLoading) {
                return;
            }

            this.$refs.confirmationFormModal.showModal(false, this.deleteRole)
        },

        async deleteRole(deleteRecord) {
            if (!deleteRecord) {
                return;
            }

            this.$v.roleModal.$touch()
            this.deleteLoading = true
            const response = await RolesService.delete(this.roleModal.id)
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                this.$emit('refresh')
                this.resetValidation()
                this.$refs.formModal.hide()
                this.positiveFeedback(this.i18nRole, 'deleted', 3)
            } else if (statusCode === '417') {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            } else if (statusCode === '422') {
                this.modalResponseErrors = response.data.errors
            } else {
                this.$refs.feedbackModal.showModal(statusCode, response.data.message)
            }

            this.deleteLoading = false
        },
        checkDefaultRole() {
            const name = this.roleModal.name
            return name === 'admin entidade' || name === 'cliente' ||
                   name === 'gerente' || name === 'promotor' ||
                   name === 'promotor lider' || name === 'supervisor' ||
                   name === 'promotor'
        },
    },
}
</script>
