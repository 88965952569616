<template>
    <div>
        <dual-list-with-labels
            v-model="rolePermissions"
            :items="permissions"
            :i18n-groups="'SECURITY.PERMISSIONS'"
            :selected-text="i18nPermissionsSelected"
            :unselected-text="i18nPermissionsSUnselected"
            :loading-selected="loadingAllPermissions"
            :busy="busy"
            :disabled="disabled"
        />
    </div>
</template>

<script>
import PermissionService from '@src/services/PermissionService.js'
import RolesService from '@src/services/RolesService.js'

import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import dualListWithLabels from '@components/dualListWithLabels'
import { unionBy } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'SECURITY'

export default {
    components: {
        dualListWithLabels,
    },
    mixins: [swalFeedbackVue],
    props: {
        value: {
            type: Array,
            required: true,
        },
        roleId: {
            type: Number,
            required: true,
        },
        parentId: {
            type: Number,
            required: false,
            default: null,
        },
        busy: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
    },
    data() {
        return {
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modalPermissionRoleId: this.roleId,
            rolePermissions: this.normalizePermissionsObject(this.value),
            permissions: [],
            loadingRolePermissions: false,
            loadingAllPermissions: false,
        }
    },
    computed: {
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nRole() {
            return this.getI18n(i18nKey, 'TITLES.role')
        },
        i18nPermissionsSelected() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'FIELDS.selected_permissions',
                modifier: 2,
            })
        },
        i18nPermissionsSUnselected() {
            return this.getI18nModified({
                prefix: i18nKey,
                suffix: 'FIELDS.unselected_permissions',
                modifier: 2,
            })
        },
    },
    watch: {
        rolePermissions(newVal) {
            this.$emit('input', newVal)
        },
    },
    methods: {
        async loadAllPermissions(rolePermissions) {
            this.loadingAllPermissions = true
            this.rolePermissions = this.normalizePermissionsObject(rolePermissions)

            const response = await PermissionService.fetchAll({
                per_page: 1000,
                page: 1,
                orderBy: 'name',
                sortedBy: 'asc'
            })
                .then((response) => {
                    return response
                })
                .catch((err) => err)

            this.permissions = this.normalizePermissionsObject(response.data.data)

            if (this.rolePermissions.length === 0) {
                if (this.parentId && this.parentId > 0) {
                    this.rolePermissions = await this.getParentRole()
                }
                else {
                    const defaultPermissions = this.permissions.filter((permission) => permission.is_default)
                    this.rolePermissions = unionBy(this.rolePermissions, defaultPermissions, 'id')
                }
            }

            this.loadingAllPermissions = false
        },

        normalizePermissionsObject(permissionsObj) {
            return permissionsObj.map((permission) => {
                const group = permission.name.split('_')[0]
                return {
                    id: permission.id,
                    name: permission.alias,
                    is_default: permission.is_default,
                    group,
                }
            })
        },

        async getParentRole() {
            const response = await RolesService.fetchById(this.parentId, { with: ['permissions'] })
                .then((response) => response)
                .catch((error) => error)

            const statusCode = response.status.toString()

            if (statusCode.charAt(0) === '2') {
                return this.normalizePermissionsObject(response.data.data.permissions)
            } else {
                this.warningFeedbackApi(this.i18nRole, response.data.errors)
            }

            return []
        }
    },
}
</script>
