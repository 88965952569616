<script>
import ApiModeVuetable from '@src/components/ApiModeVuetable'
import Breadcrumb from '@/src/components/Breadcrumb.vue'
import PageHeader from '@/src/components/PageHeader.vue'
import RoleModal from '@views/roles/roleModal'
import RolesService from '@src/services/RolesService.js'
import Layout from '@layouts/main'
import pagination from '@src/mixins/pagination'
import swalFeedbackVue from '@src/mixins/swalFeedback.vue'
import FilterBar from '@components/FilterBar'
import AddLabelButton from '@/src/components/AddLabelButton.vue'
import MoreTableButton from '@/src/components/MoreTableButton.vue'
import { debounce } from 'lodash'

const i18nCommon = 'COMMON'
const i18nKey = 'SECURITY'

export default {
    components: {
        RoleModal,
        ApiModeVuetable,
        Breadcrumb,
        PageHeader,
        Layout,
        FilterBar,
        AddLabelButton,
        MoreTableButton,
    },
    mixins: [pagination, swalFeedbackVue],
    data() {
        return {
            sortOrder: [
                {
                    field: 'alias',
                    direction: 'asc',
                },
            ],
            i18nCommon: i18nCommon,
            i18nKey: i18nKey,
            modal: {
                id: 0,
                alias: null,
                parent: {
                    id: 0,
                    name: '',
                    alias: '',
                },
                permissions: [],
                parent_id: 0,
            },
            modalIsEdit: false,
            modalHasResponseError: false,
            data: [],
            perPage: 10,
            fields: [
                {
                    name: 'alias',
                    title: this.getI18n(i18nCommon, 'name'),
                    sortField: 'alias',
                },
                {
                    name: 'parent.alias',
                    title: this.getI18n('SECURITY.TITLES.parent_role'),
                },
                {
                    name: 'actions',
                    title: '',
                },
            ],
            css: {},
            additionalParameters: {
                with: ['parent', 'permissions']
            },
            filterText: '',
        }
    },
    computed: {
        items() {
            return [
                {
                    text: 'Home',
                    href: '/',
                },
                {
                    text: this.getI18nModified({
                        prefix: 'USERS',
                        suffix: 'TITLES.collaborator',
                        modifier: 2,
                    }),
                    active: true,
                },
                {
                    text: this.getI18nModified({
                        prefix: 'SECURITY',
                        suffix: 'TITLES.role',
                        modifier: 2,
                    }),
                    active: true,
                },
            ]
        },
        i18nName() {
            return this.getI18n(i18nCommon, 'name')
        },
        i18nNewRole() {
            return this.getI18n(i18nKey, 'BUTTONS.new_role')
        },
    },
    watch: {
        filterText: debounce(function (searchText) {
            this.additionalParameters = {
                with: ['parent', 'permissions'],
                search: '',
            }
            if (searchText && searchText.length > 0) {
                this.additionalParameters.search = `name:${searchText}`
            }
            this.refreshTable()
        }, 1000),
    },
    methods: {
        i18nRole(modifier) {
            return this.getI18nModified({
                prefix: 'SECURITY.TITLES',
                suffix: 'role',
                modifier: modifier,
            })
        },

        reloadTable() {
            this.$refs.vuetable.reload()
        },

        refreshTable() {
            this.$refs.vuetable.refresh()
        },

        fetch(url, params) {
            return RolesService.fetchVuetable(url, params)
        },

        cleanModal() {
            this.$nextTick(() => this.$refs.roleModal.cleanModal())
        },
        showModal() {
            this.$nextTick(() => this.$refs.roleModal.showModal())
        },

        replaceModalData(data) {
            Object.assign(this.modal, data)

            if (!this.modal.parent) {
                this.modal.parent = {
                    id: 0,
                    name: '',
                }
            }
        },

        handleCancel(event) {
            this.cleanModal()
            this.$refs.formModal.hide()
        },

        openModalToCreate() {
            this.modalIsEdit = true
            this.cleanModal()
            this.showModal()
        },

        openModalToEdit(role) {
            this.modalIsEdit = false
            this.replaceModalData(role)
            this.showModal()
        },
    },
}
</script>

<template>
    <Layout>
        <div class="col-12">
            <Breadcrumb :items="items" />
            <div class="row mt-3 mb-3">
                <div class="col-sm-6 form-inline">
                    <filter-bar v-model="filterText"/>
                </div>
                <div class="col-sm-6">
                    <div class="text-sm-right">
                        <add-label-button
                            :title="i18nNewRole"
                            @onClick="openModalToCreate"
                        />
                    </div>
                </div>
            </div>
            <div class="card card-pdv">
                <PageHeader :title="i18nRole(2)" />
                <div class="card-body">
                    <api-mode-vuetable
                        ref="vuetable"
                        :fetch-data="fetch"
                        :fields="fields"
                        :per-page="perPage"
                        :additional-parameters="additionalParameters"
                        :sort-order="sortOrder">
                        <template
                            v-if="!props.rowData.disabled"
                            slot="actions"
                            slot-scope="props">
                            <div class="table-button-container">
                                <more-table-button @onClick="openModalToEdit(props.rowData)" />
                            </div>
                        </template>
                    </api-mode-vuetable>
                </div>
            </div>
        </div>
        <role-modal
            ref="roleModal"
            :modal-is-edit="modalIsEdit"
            :modal="modal"
            @refresh="reloadTable"
        />
    </Layout>
</template>
