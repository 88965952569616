<template>
    <b-row>
        <b-col md="5" class="pr-0">
            <b-form-group
                id="duallist-group"
                :label="unselectedText"
                label-for="duallist-unselected"
                class="label-pdv">
                <VuePerfectScrollbar
                    v-if="loadingSelected"
                    id="duallist-unselected"
                    class="scrollList"
                >
                    <div v-for="index in 4" :key="index">
                        <div
                            class="list-group-item text-center"
                            style="background-color: #5899ef; color: #fff"
                        >
                            <b-skeleton />
                        </div>
                        <b-skeleton class="ml-3 mr-3 mt-2" />
                        <hr />
                        <b-skeleton class="ml-3 mr-3 mt-2" />
                        <hr />
                        <b-skeleton class="ml-3 mr-3 mt-2 mb-2" />
                    </div>
                </VuePerfectScrollbar>
                <VuePerfectScrollbar
                    v-else
                    id="duallist-unselected"
                    class="scrollList"
                >
                    <template v-for="groupLabel in groups">
                        <b-list-group
                            v-if="unselectedItemsGroupedBy[groupLabel] && unselectedItemsGroupedBy[groupLabel].length > 0"
                            :key="`${groupLabel}-unselected`"
                        >
                            <div
                                class="list-group-item text-center"
                                style="background-color: #5899ef; color: #fff"
                                @click="selectOneGroup(groupLabel)"
                            >
                                <b>{{ getI18n(i18nGroups, groupLabel) }}</b>
                            </div>
                            <b-list-group-item
                                v-for="permission in unselectedItemsGroupedBy[groupLabel]"
                                :key="permission.id"
                                :disabled="busy || disabled"
                                @click="selectOne(permission.id)"
                                >{{ permission.name }}
                                <i
                                    v-if="permission.is_default"
                                    class="fe-alert-triangle text-danger float-right"
                                    style="cursor: pointer"
                                    :title="getI18n('SECURITY', 'WARNINGS.default_permission_warning')"
                                ></i>
                            </b-list-group-item>
                        </b-list-group>
                    </template>
                </VuePerfectScrollbar>
            </b-form-group>
        </b-col>
        <b-col
            md="2"
            align-self="center"
            class="d-flex"
            style="
                flex-direction: column;
                align-items: center;
                justify-content: center;
            "
        >
            <b-button
                size="sm"
                class="btn-pdv-gradient-blue mb-4"
                :disabled="unselectedItemsGroupedBy.length === 0 || loadingUnselected || loadingSelected || busy || disabled"
                @click="selectAll"
            >
                <i class="fe-chevrons-right"></i>
            </b-button>
            <b-button
                size="sm"
                class="btn-pdv-gradient-red"
                :disabled="selectedItems.length === 0 || loadingUnselected || loadingSelected || busy || disabled"
                @click="unselectAll"
            >
                <i class="fe-chevrons-left"></i>
            </b-button>
        </b-col>
        <b-col md="5" class="pl-0">
            <b-form-group
                id="duallist-group-selected"
                :label="selectedText"
                label-for="duallist-selected"
                class="label-pdv">
                <VuePerfectScrollbar
                    v-if="loadingSelected"
                    id="duallist-unselected"
                    class="scrollList"
                >
                    <div v-for="index in 4" :key="index">
                        <div
                            class="list-group-item text-center"
                            style="background-color: #5899ef; color: #fff"
                        >
                            <b-skeleton />
                        </div>
                        <b-skeleton class="ml-3 mr-3 mt-2" />
                        <hr />
                        <b-skeleton class="ml-3 mr-3 mt-2" />
                        <hr />
                        <b-skeleton class="ml-3 mr-3 mt-2 mb-2" />
                    </div>
                </VuePerfectScrollbar>
                <VuePerfectScrollbar
                    v-else
                    id="duallist-selected"
                    class="scrollList"
                >
                    <template v-for="groupLabel in groups">
                        <b-list-group
                            v-if="selectedItemsGroupedBy[groupLabel] && selectedItemsGroupedBy[groupLabel].length > 0"
                            :key="`${groupLabel}-selected`"
                        >
                            <div
                                class="list-group-item text-center"
                                style="background-color: #5899ef; color: #fff"
                                @click="unselectOneGroup(groupLabel)"
                            >
                                <b>{{ getI18n(i18nGroups, groupLabel) }}</b>
                            </div>
                            <b-list-group-item
                                v-for="permission in selectedItemsGroupedBy[groupLabel]"
                                :key="permission.id"
                                :disabled="busy || disabled"
                                @click="unselectOne(permission.id)"
                                >{{ permission.name }}
                                <i
                                    v-if="permission.is_default"
                                    class="fe-alert-triangle text-danger float-right"
                                    style="cursor: pointer"
                                    :title="getI18n('SECURITY', 'WARNINGS.default_permission_warning')"
                                />
                            </b-list-group-item>
                        </b-list-group>
                    </template>
                </VuePerfectScrollbar>
            </b-form-group>
        </b-col>
    </b-row>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { groupBy, uniq }  from 'lodash'

export default {
    components: {
        VuePerfectScrollbar,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },

        value: {
            type: Array,
            required: true,
        },

        loadingUnselected: {
            type: Boolean,
            default: false,
        },
        i18nGroups: {
            type: String,
            default: 'COMMOM',
        },
        loadingSelected: {
            type: Boolean,
            default: false,
        },
        unselectedText: {
            type: String,
            default: '',
        },

        selectedText: {
            type: String,
            default: '',
        },
        hideFuncion: {
            type: Function,
            required: false,
            default: function () {},
        },
        showFunction: {
            type: Function,
            required: false,
            default: function () {},
        },
        busy: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
    },

    data() {
        return {
            groups: [],
        }
    },
    computed: {
        selectedItems: {
            deep: true,
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        unselectedItems() {
            return this.items.filter(
                (item) =>
                    this.selectedItems.findIndex((s) => s.id === item.id) === -1
            )
        },
        selectedItemsGroupedBy() {
            return groupBy(this.selectedItems, 'group')
        },
        unselectedItemsGroupedBy() {
            return groupBy(this.unselectedItems, 'group')
        },
    },
    watch: {
        items(newItems) {
            this.groups = uniq(newItems.map((item) => item.group))
        },
    },
    methods: {
        unselectAll() {
            if (this.disabled) {
                return
            }

            this.selectedItems = []
        },
        selectAll() {
            if (this.disabled) {
                return
            }

            this.selectedItems = this.selectedItems.concat(this.unselectedItems)
        },
        unselectOne(permissionId) {
            if (this.disabled) {
                return
            }

            const index = this.selectedItems.findIndex(
                (item) => item.id === permissionId
            )
            this.selectedItems.splice(index, 1)
        },
        selectOne(permissionId) {
            if (this.disabled) {
                return
            }

            const index = this.unselectedItems.findIndex(
                (item) => item.id === permissionId
            )
            this.selectedItems.unshift(this.unselectedItems[index])
        },
        selectOneGroup(groupLabel) {
            if (this.disabled) {
                return
            }

            this.selectedItems = this.selectedItems.concat(
                this.unselectedItemsGroupedBy[groupLabel]
            )
        },
        unselectOneGroup(groupLabel) {
            if (this.disabled) {
                return
            }

            this.selectedItemsGroupedBy[groupLabel].forEach((item) =>
                this.unselectOne(item.id)
            )
        },
    },
}
</script>
